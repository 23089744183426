<template>
  <div class="dashboard-lead" :class="{ 'error': validation.hasErrors}">
    <div class="header">
      <lazy-image :src="image" :alt="product" :class="{ 'error': isImageInvalid }" :data-validation="isImageInvalid"/>
      <img v-show="logo" class="logo" :src="logo" alt="Logo" :class="{ 'error': isLogoInvalid }" :data-validation="isLogoInvalid">
    </div>
    <div class="body">
      <h3 v-show="product" :class="{ 'error': validation.product }" :data-validation="validation.product">{{ product }}</h3>
      <p v-show="info" :class="{ 'error': validation.info }" :data-validation="validation.info">{{ info }}</p>
    </div>
    <div v-show="cta" class="footer">
      <button class="cta" :class="{ 'error': validation.cta || validation.urlIssue }" :data-validation="validation.cta || validation.urlIssue">{{ cta }}</button>
    </div>
  </div>
</template>
<script>
import LazyImage from '@/components/commons/LazyImage'
import { mapActions } from 'vuex'

export default {
  name: 'DashboardLead',
  props: {
    image: {
      type: String,
      required: true,
      default: null,
    },
    logo: {
      type: String,
      required: true,
      default: null,
    },
    product: {
      type: String,
      required: false,
      default: () => '',
    },
    info: {
      type: String,
      required: true,
      default: null,
    },
    cta: {
      type: String,
      required: true,
      default: null,
    },
    validation: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  components: {
    LazyImage
  },
  data: () => ({
    isImageInvalid: false,
    isLogoInvalid: false
  }),
  methods: {
    ...mapActions({
      getImageSize: 'leads/getImageSize'
    })
  },
  async beforeMount() {
    try {
      const { width, height } = await this.getImageSize(this.image)
      if (width > 1504 || height > 524) {
        this.isImageInvalid = 'Max Image Size (1504x524)'
      }
    } catch(err) {
      this.isImageInvalid = 'Image Not Found'
    }
    try {
      const { width, height } = await this.getImageSize(this.logo)
      if ( (width < 400 && width > 560) || height > 156) {
        this.isLogoInvalid = 'Logo Size (400,560x156)'
      }
    } catch(err) {
      this.isLogoInvalid = 'Image Not Found'
    }
  }
}
</script>
<style lang="scss" scoped>
.dashboard-lead {
  width: 376px;
  border-radius: 4px;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
  // overflow: hidden;
  & .header {
    height: 130px;
    position: relative;
    & .lazy-image {
      height: 130px;
      &.error {
        @include lead-error-item;
      }
    }
    & img {
      &.error {
        @include lead-error-item;
      }
      &.logo {
        width: 100px;
        height: 38px;
        object-fit: cover;
        position: absolute;
        bottom: -11.8px;
        left: 18px;
        border-radius: 4px;
        &.error {
          @include lead-error-item;
        }
      }
    }
  }
  & .body {
    padding: 0 18px;
    & h3 {
      font-family: $headline;
      font-size: 16px;
      // line-height: 1.2;
      color: $dark-grey-text;
      margin-top: 21px;
      margin-bottom: 0;
      &.error {
        @include lead-error-item;
      }
    }
    & p {
      color: $dark-grey-text;
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 16px;
      &.error {
        @include lead-error-item;
      }
    }
  }
  & .footer {
    padding: 20px 18px;
    & button.cta {
      width: 100%;
      display: block;
      cursor: pointer;
      border: none;
      outline: none;
      font-family: $headline;
      font-size: 20px;
      color: $white;
      background-color: $orangey-red;
      border-radius: 4px;
      padding: 13px 15px;
      box-shadow: 0 2px 0 0 rgba(0,0,0,0.25);

      &:hover {
        color: rgba($white, .8);
      }
      &.error {
        @include lead-error-item;
      }
    }
  }

  &.error {
    @include lead-error;
  }
}
</style>