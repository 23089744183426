<template>
  <div>
    <div class="leads-filters">
      <div class="country-filter">
        <span>Select Country</span>
        <vue-tags-input
          v-model="countryKeyword"
          :autocomplete-items="filteredList"
          :add-only-from-autocomplete="true"
          :tags="selectedCountries"
          @tags-changed="filterDashboardOffers"
          :placeholder="'Select Country'"
        />
      </div>
      <div class="motivation-filter">
        <span>Select Motivation</span>
        <ul>
          <li v-for="(motivation, $index) in motivations" :key="`motivation_filter_${$index}`" :class="{ 'active': selectedMotivations.includes(motivation) }">
            <a @click.prevent="applyMotivation(motivation)">{{ motivation }}</a>
          </li>
        </ul>
      </div>
    </div>
    <ul class="offers-list">
      <li v-for="(offer, $index) in filteredDashboardOffers" :key="`lead_dashboard_offer_${$index}`">
        <h1 class="country">Country : {{ getCountryName(offer.country) }}</h1>
        <div v-for="(motivation, $mIndex) in offer.filteredMotivations" :key="`lead_dashboard_offer_motivation_${$mIndex}`">
          <h3 class="motivation">Motivation : {{ motivation.motivation }}</h3>
          <ul class="list-horizontal-scroll">
            <li v-for="(lead, $lIndex) in  leadsByIDs(motivation.offers)" :key="`lead_dashboard_offer_motivation_item_${$lIndex}`">
              <div class="leads-details">
                <dl>
                  <dt>ID: </dt>
                  <dd>{{ lead.lead_id }}</dd>
                  <dt>Type: </dt>
                  <dd>{{ lead.lead_type }}</dd>
                </dl>
              </div>
              <dashboard-lead
                :image="lead.offer_details.db_image"
                :logo="lead.offer_details.db_logo"
                :product="lead.offer_details.db_product"
                :info="lead.offer_details.db_info"
                :cta="lead.offer_details.db_cta"
                :validation="lead.validation.db" />
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DashboardLead from '@/components/leads/DashboardLead'
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: 'DashboardLeads',
  components: {
    DashboardLead,
    VueTagsInput
  },
  data: () => ({
    selectedCountries: [],
    selectedMotivations: [],
    countryKeyword: '',
  }),
  computed: {
    ...mapGetters({
      dashboardOffers: 'leads/dashboardOffers',
      leadsByIDs: 'leads/leadsByIDs',
      getCountryName: 'country/getCountryName',
      country: 'country/country',
      motivations: 'leads/motivations',
    }),
    countryList() {
      return this.country.map(item => ({
        text: `${item.Name} ${item.Unicode}`,
        key: item.Iso2
      })).sort((itemA, itemB ) => itemA.text.localeCompare(itemB.text))
    },
    filteredList() {
      return this.countryList.filter(i => (i.text.toLowerCase().indexOf(this.countryKeyword.toLowerCase()) !== -1))
    },
    filteredDashboardOffers() {
      const countryKey = this.selectedCountries.map(i => i.key.toLowerCase())
      return this.dashboardOffers.filter(item => {
        let shouldInclude = true

        if (countryKey.length > 0) {
          shouldInclude = countryKey.includes(item.country.toLowerCase())
        }
        item.filteredMotivations =  this.selectedMotivations.length > 0
          ? item.motivations.filter(mItem => this.selectedMotivations.includes(mItem.motivation))
          : item.motivations
        return shouldInclude
      })
    }
  },
  methods: {
    filterDashboardOffers(countries) {
      this.selectedCountries = countries
    },
    applyMotivation(motivation) {
      if (this.selectedMotivations.includes(motivation)) {
        const motivationIndex = this.selectedMotivations.indexOf(motivation)
        this.selectedMotivations = [...this.selectedMotivations.slice(0, motivationIndex), ...this.selectedMotivations.slice(motivationIndex + 1)]
      } else {
        this.selectedMotivations = [...this.selectedMotivations, motivation]
      }
    }
  }
}
</script>
